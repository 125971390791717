<template>
  <div class="stock_container">
    <div class="crumbs stock_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>日前交易</el-breadcrumb-item>
        <el-breadcrumb-item>现货价格分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="stock_item flex_between">
      <div class="flex_start operation">
        <div class="flex_start">
          <span>分析时段：</span>
          <el-date-picker
            v-model="periodRange.data"
            type="daterange"
            range-separator="-"
            start-placeholder="开始时段"
            end-placeholder="结束时段"
            format="YYYY-MM-DD"
          />
        </div>

        <el-select v-model="dayVal" class="select_day" placeholder="Select">
          <el-option
            v-for="item in dayList.data"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <div class="flex_start">
          <span>典型日：</span>
          <el-select
            v-model="dayType.data"
            @change="changeType"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 180px"
            class="day_type"
          >
            <el-option
              v-for="item in dayTypeList.data"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="flex_start btn_group">
        <el-button>设置</el-button>
        <el-button>说明</el-button>
      </div>
    </div>

    <div class="stock_item">
      <div class="echarts_container">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">价格大小概率</div>

          <el-select
            v-model="priceVal"
            class="select_day"
            placeholder="Select"
            @change="changePrice"
          >
            <el-option
              v-for="item in priceList.data"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div ref="price" id="price"></div>
      </div>
    </div>

    <div class="stock_item">
      <div class="echarts_container">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">价格平均偏差 （日前-实时）</div>
        </div>

        <div ref="average" id="average"></div>
      </div>
    </div>

    <div class="stock_item">
      <div class="echarts_container">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">现货均价</div>
        </div>

        <div ref="stock" id="stock"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "stock",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象
    let periodRange = reactive({ data: [] });
    let dayList = reactive({
      data: [
        { label: "近30天", value: 1 },
        { label: "近20天", value: 2 },
        { label: "近15天", value: 3 },
        { label: "近7天", value: 4 },
      ],
    });
    let dayVal = ref(1);
    let dayType = reactive({ data: [1, 2, 3] });
    let dayTypeList = reactive({
      data: [
        { label: "工作日", value: 1 },
        { label: "周六", value: 2 },
        { label: "周日", value: 3 },
        { label: "法定节假日", value: 4 },
        { label: "调休节假日", value: 5 },
      ],
    });

    let priceVal = ref(1);
    let priceList = reactive({
      data: [
        { label: "目前价格≤实时价格", value: 1 },
        { label: "目前价格≥实时价格", value: 2 },
        { label: "目前价格<实时价格", value: 3 },
        { label: "目前价格>实时价格", value: 4 },
      ],
    });

    const changeType = (val) => {
      console.log(val);
    };

    let mapsTitle = reactive({
      data: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    });

    let price = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: mapsTitle.data,
            axisTick: {
              alignWithLabel: true,
            },

            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：WM",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 14,
            data: [],
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                // barBorderRadius:[100,100, 0, 0],
                color: "#0081FF",
              },
            },
          },
        ],
      },
    });

    const priceChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("price"));

      let array = [];
      for (let i = 0; i < 24; i++) {
        array.push(Math.trunc((Math.random() * 300) / 0.5));
      }
      price.options.series[0].data = array;
      chart.setOption(price.options,true);
      window.onresize = () => {
        chart.resize();
      };
    };

    let average = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["正偏差", "负偏差"],
          //轴上的字 字体、样式
          textStyle: {
            color: "#FFFFFF",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: mapsTitle.data,
            axisTick: {
              alignWithLabel: true,
            },

            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：元/MWh",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [],
      },
    });

    const averageChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("average"));
      let serieData = [
        {
          type: "bar",
          name: "正偏差",
          barWidth: 14,
          data: [],
          stack: "side",
          itemStyle: {
            normal: {
              color: "#6355FF",
            },
          },
        },
        {
          type: "bar",
          name: "负偏差",
          barWidth: 14,
          stack: "side",

          data: [],
          itemStyle: {
            normal: {
              color: "#09D3D5",
            },
          },
        },
      ];
      let array = [],
        array2 = [];
      for (let i = 0; i < 24; i++) {
        array.push(Math.trunc((Math.random() * 300) / 0.5));
        array2.push(-Math.trunc((Math.random() * 300) / 0.5));
      }

      serieData[0].data = array;
      serieData[1].data = array2;
      average.options.series = serieData;
      chart.setOption(average.options,true);
      window.onresize = () => {
        chart.resize();
      };
    };

    let stock = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            console.log(params);
            let back = params[0].name;
            for (let i = 0, l = params.length; i < l; i++) {
              back +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "  " +
                params[i].value +
                "%";
            }
            return back;
          },
        },
        legend: {
          data: ["日前均价", "实时均价"],
          //轴上的字 字体、样式
          textStyle: {
            color: "#FFFFFF",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: mapsTitle.data,
            axisTick: {
              alignWithLabel: true,
            },

            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：元/MWh",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
              formatter: "{value} %",
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [],
      },
    });

    const stockChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("stock"));
      let serieData = [
        {
          type: "line",
          name: "日前均价",
          barWidth: 14,
          data: [],
          showSymbol: false,
          smooth: true,
          itemStyle: {
            normal: {
              color: "#6355FF",
            },
          },
        },
        {
          type: "line",
          name: "实时均价",
          barWidth: 14,
          showSymbol: false,
          smooth: true,

          data: [],
          itemStyle: {
            normal: {
              color: "#09D3D5",
            },
          },
        },
      ];
      let array = [],
        array2 = [];
      for (let i = 0; i < 24; i++) {
        array.push(Math.trunc(Math.random() * 100));
        array2.push(Math.trunc(Math.random() * 100));
      }

      serieData[0].data = array;
      serieData[1].data = array2;
      stock.options.series = serieData;
      chart.setOption(stock.options,true);
      window.onresize = () => {
        chart.resize();
      };
    };
    const changePrice = (e) => {
      nextTick(() => {
        priceChart();
      });
    };

    onMounted(() => {
      nextTick(() => {
        priceChart();
        averageChart(), stockChart();
      });
    });
    return {
      periodRange,
      dayList,
      dayVal,
      dayType,
      dayTypeList,
      changeType,
      price,
      priceChart,
      mapsTitle,
      average,
      averageChart,
      stockChart,
      stock,
      priceVal,
      priceList,
      changePrice,
    };
  },
};
</script>

<style lang="scss" scoped>
.stock_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .stock_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-date-editor.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }

      &:deep(.select_day) {
        width: 120px;
        margin: 0 32px;
      }
      &:deep(.el-select .el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.day_type .el-select__tags .el-tag--info) {
        background: #e0eaff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
  .btn_group {
    &:deep(.el-button) {
      background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
      border: none;
      color: #ffffff;
    }
  }

  .echarts_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
    background: #0b183c;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px 0;
    #price {
      width: 100%;
      height: 230px;
    }
    #average {
      width: 100%;
      height: 230px;
    }
    #stock {
      width: 100%;
      height: 230px;
    }
    .echarts_title_container {
      width: 100%;
      &:deep(.el-select .el-input__wrapper) {
        width: 150px;
        background: transparent;
        // box-shadow:none;
        margin-right: 30px;
        
      }
      .echarts_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-indent: 2em;

        &::before {
          display: block;

          content: "";
          width: 4px;
          height: 25px;
          background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
          border-radius: 0px 2px 2px 0px;
        }
      }
    }
  }
}
</style>
